import React from 'react';
import classnames from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImg from 'gatsby-background-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import data from '../content/pages/index.yml';

const IndexPage = () => {
  const imageData = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "peakopia-logo-full.png" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgImage: file(relativePath: { eq: "lime-bg.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      produceImage: file(relativePath: { in: "produce.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 400, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      missionImage: file(relativePath: { in: "mission.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 400, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={data.title} />
      <BackgroundImg
        Tag="section"
        className="hero is-medium is-primary peak-landing"
        fluid={imageData.bgImage.childImageSharp.fluid}
      >
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="logo">
                <Img fluid={imageData.logo.childImageSharp.fluid} />
            </div>
            <h2 className="subtitle">{data.subtitle}</h2>
            <Link className="button is-primary" to="/contact">Contact Us Today</Link>
          </div>
        </div>
      </BackgroundImg>
      {data.content.map((contentBlock, i) => (
        <section className="section" key={contentBlock.item}>
          <div className="container">
            <div className={classnames('columns', i % 2 && 'row-reverse')}>
              <div className="column is-6">
                <h2 className="title">{contentBlock.heading}</h2>
                <h3 className="subtitle">{contentBlock.subheading}</h3>
                <hr className="divider"></hr>
                <div className="content">
                  <p>{contentBlock.text}</p>
                </div>
                <Link className="button is-primary" to={contentBlock.link_href}>
                  {contentBlock.link_text}
                </Link>
              </div>
              <div className="column is-1" />
              <div className={classnames('column')}>
                <Img
                  fluid={
                    imageData[`${contentBlock.item}Image`].childImageSharp.fluid
                  }
                />
              </div>
            </div>
          </div>
        </section>
      ))}
    </Layout>
  );
};

export default IndexPage;
